import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import { client } from "./providers/wagmi";
import Main from "./pages/Main";
import lightTheme from "./utils/themes/light";
import darkTheme from "./utils/themes/dark";
import { useMemo, useContext } from "react";
import { WebThemeUI } from "./providers/WebThemeContext";

const App = () => {
  const { webTheme } = useContext(WebThemeUI);

  const themeMode = useMemo(() => {
    return webTheme === "light" ? lightTheme : darkTheme;
  }, [webTheme]);

  return (
    <>
      <ThemeProvider theme={themeMode}>
        <WagmiConfig client={client}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Main />} />
          </Routes>
        </WagmiConfig>
      </ThemeProvider>
    </>
  );
};

export default App;
