const breakpointValues = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  };

const commonSettings = {
    typography: {
      fontSize: 16,
      fontFamily: "Outfit",
      h1: {
        fontSize: "3.3rem",
      },
      h2: {
        fontSize: "2.3rem",
        fontWeight: 600,
        letterSpacing: "1.3px",
      },
      h3: {
        fontSize: "1.75rem",
      },
      h4: {
        fontSize: "1.5rem",
      },
      h5: {
        fontSize: "1.25rem",
        letterSpacing: "0.4px",
      },
      h6: {
        fontSize: "1rem",
      },
      body1: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: 1,
      },
      body2: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1,
      }
    },
    breakpoints: { values: breakpointValues },
}

export default commonSettings;
