import {
  AppBar,
  Toolbar,
  Box,
  useTheme,
  Button,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import LogoBlack from "./../../assets/logo/48x48_black.png";
import LogoWhite from "./../../assets/logo/48x48_white.png";
import ThemeSwitcher from "./ThemeSwitcher";
import { WebThemeUI } from "../../providers/WebThemeContext";
import { Dispatch, SetStateAction, useContext } from "react";
import { useAccount, useDisconnect } from "wagmi";

interface TopBarProps {
  setOpenWalletModal: Dispatch<SetStateAction<boolean>>;
}

const Topbar = ({ setOpenWalletModal }: TopBarProps) => {
  const theme = useTheme();
  const { webTheme, toggleTheme } = useContext(WebThemeUI);
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return (
    <>
      <AppBar
        position="relative"
        color="transparent"
        sx={{ boxShadow: "none" }}
      >
        <Toolbar
          sx={{ display: "flex", justifyContent: "space-between", height: 120 }}
        >
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: theme.palette.text.primary,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "baseline" }}>
              <Box
                component="img"
                src={webTheme === "dark" ? LogoWhite : LogoBlack}
                loading="lazy"
                alt={"NFTv Social"}
                sx={{
                  width: {
                    xs: "100px",
                    md: "150px",
                    lg: "220px",
                  },
                }}
              />
            </Box>
          </Link>
          <Box
            sx={{
              display: "flex",
              flexDirection: "inline",
              alignItems: "center",
            }}
          >
            <Box sx={{ ml: 2, mr: 2 }}>
              <ThemeSwitcher
                isChecked={webTheme === "dark"}
                toggleTheme={toggleTheme}
              />
            </Box>
            <Box>
              <Button
                sx={{
                  color: "#FFFFFF",
                  padding: {
                    xs: "4px 12px 4px 12px",
                    sm: "4px 12px 4px 12px",
                    md: "8px 24px 8px 24px",
                    lg: "8px 24px 8px 24px",
                  },
                  borderRadius: "30px",
                  background: "#9850FF",
                  fontSize: {
                    xs: "8px",
                    sm: "10px",
                    md: "16px",
                    lg: "20px",
                  },
                  fontWeight: "500",
                  fontStyle: "normal",
                  lightHeight: "32px",
                }}
                onClick={() => {
                  if (isConnected) {
                    disconnect();
                  } else {
                    setOpenWalletModal(true);
                  }
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: {
                      xs: "0.7rem",
                      md: "1rem",
                      lg: "1.5rem",
                    },
                  }}
                >
                  {isConnected
                    ? address
                      ? `${address.slice(0, 5)} ... ${address.slice(-5)}`
                      : "Error Connecting Wallet"
                    : "Connect Wallet"}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
