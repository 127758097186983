import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

import fonts from "./fonts";
import commonSettings from "./global";
import LightBG from "./../../assets/background-white.jpg";


const lightTheme = {
    main: "#FFFFFF",
    secondary: "#2C2C2C",
    background: `url(${LightBG})`,
    paperBg: "#FFFFFF"
}

const colors = {
    paper: {
      background: lightTheme.paperBg,
      card: "#1D2026",
      cardHover: "#343C49",
    },
};


const lightThemeData = {
    colors,
    palette: {
        contrastText: lightTheme.main,
        primary: {
            main: lightTheme.main,
        },
        secondary :{
            main: lightTheme.secondary,
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            ${fonts}
            body {
              background:${lightTheme.background};
              height: 100%;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-attachment: fixed;
              font-size:0.75rem;
              font-weight:400;
            }
            `,
          },
    }
    
}

const light: Theme = createTheme(deepmerge(lightThemeData, commonSettings));

export default responsiveFontSizes(light);
