import {
  OutlinedInput,
  Box,
  Typography,
  Button,
  useTheme,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Alert,
} from "@mui/material";
import Topbar from "../components/Topbar/Topbar";
import NFTvPanda from "../assets/panda-nftv.png";
import { ReactComponent as Discord } from "../assets/icons/discord.svg";
import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Github } from "../assets/icons/github.svg";
import { ReactComponent as Instagram } from "../assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter-square.svg";
import SelectWalletModal from "../components/Modals/SelectWalletModal";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import useAxios from "axios-hooks";
import { WebThemeUI } from "../providers/WebThemeContext";
interface IFormData {
  address: string;
  email: string;
}

const socialLinks = [
  {
    icon: Facebook,
    alt: "facebook",
    link: "https://www.facebook.com/nftv.social",
  },
  {
    icon: Twitter,
    alt: "twitter",
    link: "https://twitter.com/nftvsocial",
  },
  {
    icon: Instagram,
    alt: "instagram",
    link: "https://www.instagram.com/nftv.social/",
  },
  {
    icon: Discord,
    alt: "discord",
    link: "https://discord.com/invite/nftvsocial",
  },
  {
    icon: Github,
    alt: "github",
    link: "https://github.com/Nftvsocial",
  },
];

const emailAddress: string = "hello@nftv.social";

const thisYear = new Date().getFullYear();

const endpoint = "https://api.nftv.social";

const Main = () => {
  const theme = useTheme();
  const { webTheme } = useContext(WebThemeUI);
  const [openWalletModal, setOpenWalletModal] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>({
    address: "",
    email: "",
  });

  const position: SnackbarOrigin = {
    vertical: "top",
    horizontal: "right",
  };

  const [{ data: postData, loading: postLoading }, executePost] = useAxios(
    {
      url: `${endpoint}/address`,
      method: "POST",
    },
    { manual: true }
  );

  const insertData = (data: IFormData) => {
    if (Object.values(data).includes("")) {
      return;
    } else {
      executePost({
        data: {
          email: data.email,
          address: data.address,
        },
      });
    }
  };

  const { isConnected, address } = useAccount();

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleCloseWalletModal = () => {
    setOpenWalletModal(false);
  };

  useEffect(() => {
    if (postData) {
      if (postData.statusCode) {
        setOpenSnackbar(true);
      }
    }
  }, [postData]);

  useEffect(() => {
    if (isConnected && address) {
      setFormData((prev) => ({
        ...prev,
        address: address,
      }));
    }
  }, [address, isConnected]);

  return (
    <>
      <Box sx={{ maxWidth: "100%", height: "148px" }}>
        <Topbar setOpenWalletModal={setOpenWalletModal} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            textAlign: {
              xs: "center",
              sm: "center",
              md: "left",
              lg: "left",
            },
            pl: 5,
            pr: 5,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              lineHeight: "150%",
              color: theme.palette.secondary.main,
            }}
            variant="h1"
            component={"h1"}
          >
            NFTv Whitelist Campaign
          </Typography>
          <Typography
            variant="h3"
            component={"h3"}
            sx={{ color: theme.palette.secondary.main }}
          >
            Be prepared for one of the biggest NFT launches of the year! The
            NFTv Pandas are coming to you.
          </Typography>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ display: "flex", pt: 2, pb: 2 }}
          >
            <Box sx={{ display: "flex", pr: 2 }}>
              <OutlinedInput
                sx={{
                  borderRadius: "24px",
                  width: { xs: "200px", sm: "200px", md: "300px", lg: "400px" },
                  "& label.Mui-focused": {
                    display: "none",
                  },
                  "& legend": {
                    display: "none",
                  },
                  "& .MuiInputBase-input": {
                    color:
                      webTheme === "dark"
                        ? "#FFFFFF !important"
                        : "#000000 !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor:
                      webTheme === "dark"
                        ? "#FFFFFF !important"
                        : "#000000 !important",
                  },
                }}
                // InputLabelProps={{ shrink: false }}
                id="component-helper"
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                placeholder="Email Address"
                label="Name"
              />
            </Box>
            <Box sx={{ display: "flex", pl: 2 }}>
              <Button
                sx={{
                  color: "#FFFFFF",
                  padding: {
                    xs: "4px 12px 4px 12px",
                    sm: "4px 12px 4px 12px",
                    md: "8px 24px 8px 24px",
                    lg: "8px 24px 8px 24px",
                  },
                  borderRadius: "30px",
                  background: "#9850FF",
                  fontSize: {
                    xs: "8px",
                    sm: "10px",
                    md: "16px",
                    lg: "20px",
                  },
                  fontWeight: "500",
                  fontStyle: "normal",
                  lightHeight: "32px",
                }}
                onClick={() => {
                  if (isConnected) {
                    insertData(formData);
                  }
                }}
              >
                {postLoading
                  ? "Loading"
                  : isConnected
                  ? "Join Now"
                  : "Please Connect your Wallet"}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            pr: 5,
            pl: 5,
          }}
        >
          <Box
            component="img"
            src={NFTvPanda}
            loading="lazy"
            alt={"NFTv Panda"}
            sx={{
              width: {
                xs: "400px",
                sm: "500px",
                md: "550px",
                lg: "600px",
              },
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: {
            xs: "center",
            md: "left",
            lg: "left",
          },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "100%", lg: "40%" },
            // display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            // justifyContent: {
            //   xs: "center",
            //   md: "left",
            //   lg: "left",
            // },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                md: "left",
                lg: "left",
              },
              alignItems: "center",
              mt: 6,
              ml: {
                xs: 0,
                md: 4,
                lg: 4,
              },
            }}
          >
            {socialLinks.map(({ icon: Icon, alt, link }) => (
              <IconButton
                key={alt}
                href={link}
                sx={{ mr: 2, color: theme.palette.secondary.main }}
              >
                <Icon style={{ fill: "currentColor" }} />
              </IconButton>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "center",
                md: "left",
                lg: "left",
              },
              ml: {
                xs: 0,
                md: 5,
                lg: 5,
              },
            }}
          >
            <Typography
              variant={"subtitle2"}
              component="h6"
              sx={{
                color: theme.palette.secondary.main,
                fontSize: {
                  xs: "0.5rem",
                  md: "0.7rem",
                  lg: "1rem",
                },
              }}
            >
              {emailAddress} - &copy; COPYRIGHT NFTV {thisYear} - ALL RIGHT
              RESERVED
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: { xs: "0px", md: "60%", lg: "60%" } }}></Box>
      </Box>
      <SelectWalletModal
        isOpen={isConnected ? false : openWalletModal}
        closeModal={handleCloseWalletModal}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        key={position.vertical + position.horizontal}
        anchorOrigin={position}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">
            {postData
              ? postData.statusCode === 200
                ? "Thank you for your application. We will update you again."
                : postData.status
              : "error"}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default Main;
