import { useEffect, useState, createContext, ReactNode, FC } from "react";

export const WebThemeUI = createContext({
  webTheme: "dark",
  toggleTheme: () => {},
});

interface webThemeProp {
  children: ReactNode;
}

const WebThemeContext: FC<webThemeProp> = (props: webThemeProp) => {
  const [webTheme, setTheme] = useState(
    window.localStorage.getItem("theme") || "dark"
  );

  const setMode = (mode: string) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (webTheme === "light") {
      setMode("dark");
    } else {
      setMode("light");
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches &&
    !localTheme
      ? setMode("dark")
      : localTheme
      ? setTheme(localTheme)
      : setMode("light");
  }, []);

  return (
    <WebThemeUI.Provider value={{ webTheme, toggleTheme }}>
      {props.children}
    </WebThemeUI.Provider>
  );
};

export default WebThemeContext;
