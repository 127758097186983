import { Modal, Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { useConnect } from "wagmi";
import { ReactComponent as Metamask } from "../../assets/icons/metamask.svg";
import { ReactComponent as WalletConnect } from "../../assets/icons/walletconnect.svg";
import { WebThemeUI } from "../../providers/WebThemeContext";
import { isMobile } from "react-device-detect";

interface SelectWalletModelProps {
  isOpen: boolean;
  closeModal: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  pb: 2,
  borderRadius: "20px",
};

const SelectWalletModel = ({ isOpen, closeModal }: SelectWalletModelProps) => {
  const { connect, connectors } = useConnect();
  const { webTheme } = useContext(WebThemeUI);
  const handleWeb3WalletIcon = (name: string) => {
    switch (name) {
      case "metaMask":
        return <Metamask style={{ width: "40px", height: "40px" }} />;
      case "walletConnect":
        return <WalletConnect style={{ width: "40px", height: "40px" }} />;
      default:
        return <Metamask style={{ width: "40px", height: "40px" }} />;
    }
  };
  return (
    <>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {connectors.map((connector) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "verticle",
                justifyContent: "left",
                alignItems: "center",
                p: 2,
                ml: 5,
              }}
              key={connector.id}
            >
              {isMobile && connector.id === "metaMask" ? (
                <></>
              ) : (
                <Button
                  disabled={!connector.ready}
                  onClick={() => connect({ connector })}
                  startIcon={handleWeb3WalletIcon(connector.id)}
                >
                  <Typography
                    sx={{
                      color:
                        webTheme === "dark"
                          ? "#000000 !important"
                          : "#000000 !important",
                    }}
                  >
                    {connector.name}
                  </Typography>
                </Button>
              )}
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default SelectWalletModel;
