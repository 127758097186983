import OutfitBlackWoff2 from "./../../assets/fonts/Outfit-Black.woff2";
import OutfitBoldWoff2 from "./../../assets/fonts/Outfit-Bold.woff2";
import OutfitExtraBoldWoff2 from "./../../assets/fonts/Outfit-ExtraBold.woff2";
import OutfitExtraLightWoff2 from "./../../assets/fonts/Outfit-ExtraLight.woff2";
import OutfitLightWoff2 from "./../../assets/fonts/Outfit-Light.woff2";
import OutfitMediumWoff2 from "./../../assets/fonts/Outfit-Medium.woff2";
import OutfitRegularWoff2 from "./../../assets/fonts/Outfit-Regular.woff2";
import OutfitSemiBoldWoff2 from "./../../assets/fonts/Outfit-SemiBold.woff2";
import OutfitThinWoff2 from "./../../assets/fonts/Outfit-Thin.woff2";

const outfit = `

@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: local('Outfit'), local('Outfit-Thin'), url(${OutfitThinWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: local('Outfit'), local('Outfit-ExtraLight'), url(${OutfitExtraLightWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Outfit'), local('Outfit-Light'), url(${OutfitLightWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Outfit'), local('Outfit-Regular'), url(${OutfitRegularWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Outfit'), local('Outfit-Medium'), url(${OutfitMediumWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Outfit'), local('Outfit-SemiBold'), url(${OutfitSemiBoldWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Outfit'), local('Outfit-Bold'), url(${OutfitBoldWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local('Outfit'), local('Outfit-ExtraBold'), url(${OutfitExtraBoldWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}


@font-face {
    font-family: "Outfit";
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: local('Outfit'), local('Outfit-Black'), url(${OutfitBlackWoff2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
}

`
export default outfit;
