import { createTheme, responsiveFontSizes, Theme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

import fonts from "./fonts";
import commonSettings from "./global";

import DarkBG from "./../../assets/background-dark.jpg";

const darkTheme = {
    main: "#7D30EB",
    secondary: "#FFFFFF",
    background: `url(${DarkBG})`,
    paperBg: "#7D30EB"
}

const colors = {
    paper: {
      background: darkTheme.paperBg,
      card: "#1D2026",
      cardHover: "#343C49",
    },
  };


const darkThemeData = {
    colors,
    palette: {
        contrastText: darkTheme.main,
        primary: {
            main: darkTheme.main
        },
        secondary: {
            main: darkTheme.secondary
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            ${fonts}
            body {
              background:${darkTheme.background};
              height: 100%;
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              background-attachment: fixed;
              font-size:0.75rem;
              font-weight:400;
            }
            `,
          },
    }
    
}

const dark: Theme = createTheme(deepmerge(darkThemeData, commonSettings));

export default responsiveFontSizes(dark);
